<template>
  <div>
    <ul class="app-header-tabs">
      <li class="app-header-tab">
        <app-link to="Home" class="tabs-link">首页</app-link>
      </li>
      <li class="app-header-tab">
        <app-link to="Idea" class="tabs-link">奇思妙想</app-link>
      </li>
      <li class="app-header-tab">
        <app-link to="Range" class="tabs-link">圈子</app-link>
      </li>
      <!-- <li class="app-header-tab">
        <app-link to="Test2" class="tabs-link">发现</app-link>
      </li> -->
    </ul>
  </div>
</template>

<script>
import AppLink from "./Link";
export default {
  components: { AppLink },
};
</script>

<style scoped>
.app-header-tabs {
  border-bottom: none;
  display: flex;
  flex-shrink: 0;
  margin: 0 16px;
}
.app-header-tabs .app-header-tab {
  padding: 0 15px;
}
.app-header-tabs .app-header-tab:hover {
  color: #121212;
}
.tabs-link {
  position: relative;
  display: inline-block;
  padding: 14px 0;
  font-size: 15px;
  line-height: 22px;
  color: #8590a6;
  text-align: center;
}

.router-link-exact-active {
  color: #121212;
  font-weight: 600;
}
.router-link-exact-active::after {
  position: absolute;
  right: 0;
  bottom: -1px;
  left: 0;
  height: 4px;
  background: #06f;
  content: "";
}
</style>