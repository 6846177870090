import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Layout from '@/layout'

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/Home',
    children: [
      {
        path: 'Home',
        component: () => import('@/views/Home')
      },
      {
        path: 'Idea',
        component: () => import('@/views/Idea')
      },
      {
        path: 'Range',
        component: () => import('@/views/Range')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
