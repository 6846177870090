<template>
  <div>
    <a-affix :offset-top="0">
      <Header />
    </a-affix>
    <div class="app-wrapper">
      <App-Main />
    </div>
     <a-back-top />
  </div>
</template>

<script>
import { AppMain, Header } from "./components";
export default {
  components: {
    AppMain,
    Header,
  },
};
</script>
<style scoped>
/* .app-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
} */
.app-wrapper {
  display: flex;
  align-items: flex-start;
  width: 1000px;
  /* padding: 0 16px; */
  margin: 10px auto;
  height: 1000px;
}
</style>