<template>
  <div class="app-header">
    <div class="app-header-inner">
      <Logo />
      <Header-Item />
    </div>
  </div>
</template>
<script>
import HeaderItem from "./HeaderItem";
import Logo from "./Logo";
export default {
  components: { HeaderItem, Logo },
};
</script>

<style scoped>
.app-header {
  position: relative;
  z-index: 100;
  min-width: 1000px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 1px 3px rgb(18 18 18 / 10%);
  
}
.app-header .app-header-inner {
    position: relative;
    display: flex;
    width: 1156px;
    height: 52px;
    padding: 0 16px;
    margin: 0 auto;
    align-items: center;
    transition: transform .3s,-webkit-transform .3s;
}
</style>