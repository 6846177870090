<template>
  <main>
    <router-view :key="key" v-slot="{ Component }">
      <transition name="fade-transform" mode="out-in">
        <keep-alive include="Home">
          <component :is="Component"></component>
        </keep-alive>
      </transition>
    </router-view>
  </main>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    key() {
      return this.$route.path;
    },
  },
  mounted(){
    console.log(this.$store.state)
  }
};
</script>
<style scoped>
</style>
