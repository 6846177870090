<template>
  <a-config-provider>
    <template #renderEmpty>
      <div style="text-align: center">
        <!-- <a-icon type="smile" style="font-size: 50px" /> -->
        <p>暂无</p>
      </div>
    </template>
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
/* @import "./assets/css/normalize.css"; */
body {
  /* font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC,
    Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei,
    sans-serif; */
  font-size: 15px;
  color: #121212 !important;
  background-color: #f6f6f6 !important;
}
a {
  color: inherit;
  text-decoration: none;
}
li {
  list-style-type: none;
}
#app {
  height: 100%;
  width: 100%;
}
.mainColumn {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 10px;
  margin-bottom: 0;
  width: 100%;
}
</style>